<template>
  <div class="menu">
    <div class="menu-wrapper">
      <h1>
        <img src="./img/menu/menu_ttl.png" alt="タイムレースルーム" />
      </h1>
      <nav class="btn-back" @click.stop="back('トップ[画面:がめん]')">
        <a href="#"><span class="sp-none">もどる</span></a>
      </nav>
      <nav class="btn-logout">
        <a href="#" @click.stop="logout"
          ><span class="sp-none">ログアウト</span></a
        >
      </nav>
      <div
        v-for="(region, region_key) in menu"
        :key="region_key"
        :class="['region', region.open ? 'open' : null]"
        :style="'order:' + region.order"
        @click="region.open = !region.open"
      >
        <h2><span v-html="region_key"></span></h2>
        <ul class="facilities">
          <li
            v-for="facility in region.value"
            :key="facility.id"
            :class="[
              'facility',
              facility.isNew
                ? 'new'
                : facility.completed
                ? 'complete'
                : facility.already
                ? 'already'
                : null
            ]"
            @click.stop="clickFacility(facility)"
          >
            <div class="facility-wrapper">
              <h3 v-html="facility.cname"></h3>
              <div class="stamp-wrapper">
                <ul class="stamp-status">
                  <template v-for="(question, index) of facility.questions">
                    <li
                      v-if="index > 0"
                      :key="facility.id + 'q' + index"
                      :class="['q' + index, question > 0 ? 'done' : null]"
                    >
                      Q{{ index }}
                    </li>
                  </template>
                  <li
                    :key="facility.id + 'ar'"
                    :class="['ar', facility.questions[0] > 0 ? 'done' : null]"
                  >
                    AR
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="region overall_ranking" @click.stop="clickRanking()">
        <h2>
          <span>
            <ruby><rb>総合</rb><rt>そうごう</rt></ruby
            >ランキング
          </span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue';
import DialogFacility from './components/DialogFacility.vue';
import dialogs from '../../mixins/dialogs';
import back from '@/mixins/back';
import { formatAllasLine } from '../../service/formatter';
export default {
  name: 'Menu',
  components: {},
  mixins: [dialogs, back],
  data() {
    return {
      menu: null,
      resources: {}
    };
  },
  async beforeCreate() {
    this.$tryLoadingAsync(async () => {
      const menu = await this.$store.dispatch('api/getAbility');
      for (let region_key in menu) {
        const region = menu[region_key];
        region.open = false;
        for (let facility of region.value) {
          facility.fname = formatAllasLine.format(facility.name);
          facility.cname = facility.fname + `(${facility.city})`;
          let already = true;
          let next = 0;
          for (let q = 1; q < facility.questions.length; q++) {
            const flag = facility.questions[q];
            already &= flag;
            if (flag) {
              next = q;
            }
          }
          facility.already = already;
          facility.completed = already & facility.questions[0];
          facility.next = next + 1;
          let sum = Object.keys(facility.questions)
            .map(k => facility.questions[k])
            .reduce((p, c) => p + c, 0);
          facility.isNew = sum < 1;
          this.loadResource(facility.image_id);
        }
      }
      this.menu = menu;
    });
  },
  methods: {
    async loadResource(image_id) {
      if (this.resources[image_id]) {
        return;
      }
      await this.$store
        .dispatch('api/getImageStream', {
          url: image_id,
          onRead: () => {}
        })
        .then(data => data.blob())
        .then(data => {
          this.resources[image_id] = URL.createObjectURL(data);
        })
        .catch(error => {
          console.error(error);
        });
    },
    clickRanking() {
      this.$router
        .push({ name: 'Ranking', params: { id: 'total' } })
        .catch(() => {});
    },
    async clickFacility(facility) {
      const result = await this.$confirm({
        component: DialogFacility,
        props: {
          facility: facility,
          resources: this.resources
        }
      });
      this.$tryLoadingAsync(async () => {
        if (result >= 0) {
          const data = await this.$store.dispatch('api/getFacility', {
            id: facility.id,
            no: result
          });
          console.log('getFacility', result, data);
          if (result === 0) {
            // AR
            this.$router
              .push({
                name: 'Landmark',
                params: {
                  title: facility.fname,
                  id: facility.id,
                  no: result,
                  text: data.text,
                  facility: facility
                }
              })
              .catch(() => {});
          } else {
            // Q
            this.$router
              .push({
                name: 'Question',
                params: {
                  title: facility.fname,
                  id: facility.id,
                  no: result,
                  length: facility.questions.length,
                  data: data
                }
              })
              .catch(() => {});
          }
        }
      });
    },
    async logout() {
      const result = await this.$confirm({
        component: Dialog,
        props: {
          title: 'ログアウトの確認',
          message: 'ログアウトしますか？',
          options: [
            { text: 'はい', value: true },
            { text: 'いいえ', value: false }
          ]
        }
      });
      if (result) {
        this.$store.commit('logout');
        this.$router.push({ path: '/' });
      }
    }
  }
};
</script>

<style scoped>
li.facility.new::after {
  content: '';
  display: block;
  width: 84px;
  height: 84px;
  background-image: url('./img/menu/ico_new.png');
  background-size: cover;
  position: absolute;
  bottom: calc(100% - 95px);
  left: 10px;
  z-index: 2;
}
</style>

<template>
  <Modal :class="'dialog-facilitymenu'" @click="close">
    <div class="dialog-wrapper">
      <div class="dialog-header">
        <h1 v-html="facility.cname"></h1>
        <button class="btn-ranking" @click="ranking">ランキング</button>
        <button class="btn-close" @click="close">戻る</button>
      </div>
      <div class="dialog-info">
        <img :src="resources[facility.image_id]" />
        <div class="dialog-info-detail">
          <p>
            <ruby><rb>問題</rb><rt>もんだい</rt></ruby
            >またはARを<ruby><rb>選</rb><rt>えら</rt></ruby
            >ぼう！
          </p>
          <div class="stamp-wrapper">
            <ul class="stamp-status">
              <template v-for="(question, index) of facility.questions">
                <li
                  v-if="index > 0"
                  :key="facility.id + 'q' + index"
                  :class="['q' + index, question > 0 ? 'done' : null]"
                >
                  <a v-if="index <= facility.next" @click="select(index)"
                    >Q{{ index }}</a
                  >
                  <span v-else>Q{{ index }}</span>
                </li>
              </template>
              <li
                :key="facility.id + 'ar'"
                :class="['ar', facility.questions[0] > 0 ? 'done' : null]"
              >
                <a @click="select(0)">AR</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import modal from '@/mixins/modal';
export default {
  name: 'Dialog',
  components: {
    Modal: () => import('@/components/Modal.vue')
  },
  mixins: [modal],
  props: {
    facility: { type: Object, required: true },
    resources: { type: Object, required: true },
    onClose: { type: Function, required: true }
  },
  data() {
    return {
      visible: false,
      result: null
    };
  },
  methods: {
    ranking() {
      this.$router
        .push({ name: 'Ranking', params: { id: this.facility.id } })
        .catch(() => {});
      this.close();
    },
    select(value) {
      this.onClose(value);
      this.close();
    }
  }
};
</script>

<style scoped src="../css/dialog.css"></style>
<style scoped>
.dialog-facilitymenu .modal-container .dialog-wrapper {
  max-width: 70vh;
}
.dialog-facilitymenu
  .modal-container
  .dialog-wrapper
  .dialog-info
  .dialog-info-detail
  .stamp-wrapper
  .stamp-status
  a {
  pointer-events: all;
}
/*
.dialog-facilitymenu .modal-container .dialog-wrapper .dialog-info img {
  object-fit: cover;
  max-height: 50vh;
}
*/
</style>

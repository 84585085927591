var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu-wrapper"},[_vm._m(0),_c('nav',{staticClass:"btn-back",on:{"click":function($event){$event.stopPropagation();return _vm.back('トップ[画面:がめん]')}}},[_vm._m(1)]),_c('nav',{staticClass:"btn-logout"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.logout($event)}}},[_c('span',{staticClass:"sp-none"},[_vm._v("ログアウト")])])]),_vm._l((_vm.menu),function(region,region_key){return _c('div',{key:region_key,class:['region', region.open ? 'open' : null],style:('order:' + region.order),on:{"click":function($event){region.open = !region.open}}},[_c('h2',[_c('span',{domProps:{"innerHTML":_vm._s(region_key)}})]),_c('ul',{staticClass:"facilities"},_vm._l((region.value),function(facility){return _c('li',{key:facility.id,class:[
            'facility',
            facility.isNew
              ? 'new'
              : facility.completed
              ? 'complete'
              : facility.already
              ? 'already'
              : null
          ],on:{"click":function($event){$event.stopPropagation();return _vm.clickFacility(facility)}}},[_c('div',{staticClass:"facility-wrapper"},[_c('h3',{domProps:{"innerHTML":_vm._s(facility.cname)}}),_c('div',{staticClass:"stamp-wrapper"},[_c('ul',{staticClass:"stamp-status"},[_vm._l((facility.questions),function(question,index){return [(index > 0)?_c('li',{key:facility.id + 'q' + index,class:['q' + index, question > 0 ? 'done' : null]},[_vm._v(" Q"+_vm._s(index)+" ")]):_vm._e()]}),_c('li',{key:facility.id + 'ar',class:['ar', facility.questions[0] > 0 ? 'done' : null]},[_vm._v(" AR ")])],2)])])])}),0)])}),_c('div',{staticClass:"region overall_ranking",on:{"click":function($event){$event.stopPropagation();return _vm.clickRanking()}}},[_c('h2',[_c('span',[_c('ruby',[_c('rb',[_vm._v("総合")]),_c('rt',[_vm._v("そうごう")])],1),_vm._v("ランキング ")])])])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('img',{attrs:{"src":require("./img/menu/menu_ttl.png"),"alt":"タイムレースルーム"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"sp-none"},[_vm._v("もどる")])])}]

export { render, staticRenderFns }